import React from 'react';

const Switch = ({ onChange, className, checked, id = 'darkmode-switch' }) => (
  <div className={className}>
    <input
      className="switchInput__checkbox"
      type="checkbox"
      id={id}
      onChange={onChange}
      checked={checked}
    />
    <label
      className="inline-block relative cursor-pointer outline-none leading-none rounded-full border border-solid border-gray-100 bg-gray-100 switchInput__switch"
      htmlFor={id}
    ></label>
  </div>
);

Switch.propTypes = {};

Switch.defaultProps = {};

export default Switch;
