import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import React from 'react';

import Sky from 'assets/svg/sky.svg';
import Switch from './switch';
import ThemeContext from '../context/ThemeContext';

const Header = ({ siteTitle }) => (
  <ThemeContext.Consumer>
    {theme => (
      <header className="header">
        <div className="flex justify-between items-center px-6 sm:px-16 py-6 sm:py-8">
          <div>
            <h1 className="text-xl flex items-center">
              <Sky className="text-white fill-current h-8 mr-2" />
              <AniLink
                cover
                direction="right"
                bg="#242336"
                to="/"
                className="text-white"
              >
                {siteTitle}
              </AniLink>
            </h1>
          </div>
          <div className="flex pl-3">
            <Switch onChange={e => theme.toggleDark()} checked={theme.dark} />
            <span className="w-24 text-xs text-right text-white">
              <span>
                Dark mode <span className="ml-1">☾</span>
              </span>
            </span>
          </div>
        </div>
      </header>
    )}
  </ThemeContext.Consumer>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
